.stepper-container {
  width: 100%;
  margin: 0 0 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .stepper-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

  }
  .step-label-container {
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .circle-label {
      width: auto;
      height: 40px;
      font-size: 22px;
      font-weight: 800;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      background-color: #fff;
      border-radius: 99px;
      display: flex;
      justify-content: center;
      align-items: center;
      color:#223065;
      margin: 0 8px ;
    }
  }

  .progress-dot-bar {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    padding: 0;
    .progress-dot {
      margin: 0 0 0 0;
      background-color: #fff;
      height: 13px;
      width: 13px;
      border-radius: 99px;
      border: 2px solid #223065;
      &.active {
        background-color: #223065;
      }
    }
    .progress-dot ~ .progress-dot {
      margin: 0 0 0 12px;

    }
    .background-line {
      height: 2px;
      width: 100%;
      background-color: #ddd;
      position: absolute;
      top: 50%;
      z-index: -1;
    }
  }


}