.admin-page-container {
  width:  100%;
  height: calc(100% - 130px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

.page-container {
  margin: 60px 12px;
  height: 90%;
  overflow-y: auto;
}


.admin-menu-container {
  width: 220px;
  height: 100%;
  padding: 28px 0;
  margin: 0 ;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #222222;
  .menu-item {
    height: auto;
    list-style-type: none;


    &:hover {
      background-color: #f1f1f1;
    }
    a {
      padding: 0 18px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      text-decoration: none;
      color: #222222;
      &.selected {
        font-weight: 800;
        color: #223065;
        text-decoration: underline;
      }
    }
  }

  .action-bar{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }



}


